<template>
  <div class="mt-4">
    <v-container class="my-3">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="500">
          <v-card>
            <v-card-title class="text-h5">
              กรุณากรอกรหัสลงทะเบียน
            </v-card-title>

            <v-card-text>
              <v-text-field
                type="text"
                prepend-icon="mdi-lock"
                maxlength="10"
                v-model="coupon"
                label="รหัสลงทะเบียน..."
                required
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                ยกเลิก
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="registerCourse(subject)"
              >
                ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <div class="row">
        <div class="col-md-5 col-sm-5 col-xs-12">
          <v-carousel>
            <v-carousel-item :src="imgPath(subject.subjectImg, 'subject')">
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="col-md-7 col-sm-7 col-xs-12">
          <div class="pl-6">
            <h1 class="mb-0">{{ subject.subjectName }}</h1>
            <v-card-actions class="pa-0">
              <!-- <h2 class="font-weight-light pt-3 primary--text">
                ราคา {{ numberWithCommas(subject.subjectPrice) }} บาท
              </h2> -->
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-row class="mt-3">
              <v-col cols="12">
                <h3 class="red--text">รายละเอียดรายวิชา</h3>
              </v-col>
              <v-col class="ml-4">
                <p class="font-weight-thin" v-html="subject.subjectDesc"></p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row class="my-3" no-gutters>
              <v-col cols="12">
                <h3 class="red--text">จำนวนบทเรียนทั้งหมด</h3>
              </v-col>
              <v-col class="ml-5">
                <span class="black--text">
                  {{ subject.totalLesson }} บทเรียน</span
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row class="my-3" no-gutters>
              <v-col cols="12">
                <h3 class="red--text">จำนวนชั่วโมงต่อบทเรียน</h3>
              </v-col>
              <v-col class="ml-5">
                <span class="black--text">
                  {{ subject.hourLesson }} ชั่วโมง</span
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row class="my-3" no-gutters>
              <v-col cols="12">
                <h3 class="red--text">จำนวนชั่วโมงเรียนทั้งหมด</h3>
              </v-col>
              <v-col class="ml-5">
                <span class="black--text">
                  {{ subject.totalHour }} ชั่วโมง</span
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row class="my-3" no-gutters>
              <v-col cols="12">
                <h3 class="red--text">ราคา</h3>
              </v-col>
              <v-col class="ml-5">
                <h3 class="info--text mt-1" v-if="subject.subjectPrice <= 1">
                  <span class="black--text"> ฟรี</span>
                </h3>
                <h3 class="info--text mt-1" v-else>
                  <span class="black--text">
                    {{ numberWithCommas(subject.subjectPrice) }} บาท</span
                  >
                </h3>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="my-3" no-gutters>
              <v-col cols="12">
                <h3 class="red--text">ชื่อผู้สอน</h3>
              </v-col>
              <div
                v-if="teachers.length > 0"
              >
                <v-col
                  class="ml-5"
                  v-for="teacher in teachers"
                  :key="teacher.teacherId"
                >
                  <span class="black--text"> {{ teacher.teacherName }} </span>
                </v-col>
              </div>
              <div v-else>-</div>
            </v-row>
<!-- 
            <v-btn
              block
              @click="couponConfirm(subject)"
              v-if="isUser"
              color="primary"
              class="mt-2"
              >ลงทะเบียน</v-btn
            > -->

            <v-dialog v-model="isShowCoupon" persistent max-width="600px">
            </v-dialog>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-sm-12 col-xs-12 col-md-12">
          <v-tabs>
            <v-tab>Description</v-tab>
            <v-tab>Materials</v-tab>
            <v-tab>REVIEWS</v-tab>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ultricies mi eget mauris pharetra et. Vel pretium lectus quam id
                leo in vitae turpis massa. Orci dapibus ultrices in iaculis
                nunc. At auctor urna nunc id cursus metus. Integer feugiat
                scelerisque varius morbi enim nunc. Aliquam sem et tortor
                consequat id porta nibh venenatis cras. Pellentesque pulvinar
                pellentesque habitant morbi tristique senectus et netus.
                Malesuada nunc vel risus commodo viverra maecenas. Neque
                volutpat ac tincidunt vitae semper quis.
              </p>
            </v-tab-item>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ultricies mi eget mauris pharetra et. Vel pretium lectus quam id
                leo in vitae turpis massa. Orci dapibus ultrices in iaculis
                nunc. At auctor urna nunc id cursus metus. Integer feugiat
                scelerisque varius morbi enim nunc. Aliquam sem et tortor
                consequat id porta nibh venenatis cras. Pellentesque pulvinar
                pellentesque habitant morbi tristique senectus et netus.
                Malesuada nunc vel risus commodo viverra maecenas. Neque
                volutpat ac tincidunt vitae semper quis.
              </p>
            </v-tab-item>
            <v-tab-item>
              <v-list three-line="true" avatar="true">
                <v-list-item-group v-model="item" color="primary">
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    inactive="true"
                  >
                    <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.title"></v-list-item-title
                      ><v-rating
                        v-model="rating"
                        class=""
                        background-color="warning lighten-3"
                        color="warning"
                        dense
                      ></v-rating>
                      <v-list-item-subtitle
                        v-html="item.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs>
        </div>
      </div> -->
    </v-container>
  </div>
</template>
<script>
import "@/mixins/generalMixin";

export default {
  name: "Product",
  data: () => ({
    studentData: null,
    isUser: false,
    rating: 4.5,
    item: 5,
    subject: {},
    dialog: false,
    teachers: [],
    coupon: "",
  }),
  methods: {
    couponConfirm(subject) {
      this.dialog = true;
      this.subject = subject;
    },

    registerCourse(subject) {
      if (!this.coupon) {
        this.$swal({
          icon: "error",
          text: "กรุณากรอกรหัสลงทะเบียน",
          confirmButtonText: "ตกลง",
          allowOutSideClick: false,
        });
      }

      let { stdId } = this.studentData;

      if (stdId) {
        const date = new Date();
        const startDate = date.toISOString().substring(0, 10);
        const endDate = new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        );

        const addCourseParam = {
          subjectId: subject.subjectId,
          stdId: stdId,
          objective: "ไม่ระบุ",
          dates: [startDate, endDate.toISOString().substring(0, 10)],
          subjectCoupon: this.coupon,
        };

        const formData = new FormData();

        for (const key in addCourseParam) {
          formData.append(key, addCourseParam[key]);
          console.log(addCourseParam[key]);
        }

        this.$http
          .post(
            `${process.env.VUE_APP_API_PATH}/subject/addStudentSubject.php`,
            formData
          )
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;

              this.$swal
                .fire({
                  icon: "success",
                  title: "ลงทะเบียนสำเร็จ",
                  confirmButtonText: "ตกลง",
                  allowOutSideClick: false,
                })
                .then((result) => {
                  if (result) {
                    this.$router.push({ name: "Index" });
                  }
                });
            }
          })
          .catch((err) => {
            if (err.response.data.message) {
              this.$swal({
                icon: "error",
                text: err.response.data.message,
                confirmButtonText: "ตกลง",
                allowOutSideClick: false,
              });
              return;
            }
          });
      }
    },

    getSubject() {
      const jsonData = JSON.stringify({
        subjectId: this.$route.query.subjectId,
      });
      const data = this.post("/subject/getSubject.php", jsonData);
      data.then(async (res) => {
        const subjectTeacher = await this.post("/subject/getSubjectTeacher.php", jsonData);

        this.subject = res.data[0];
        this.teachers = subjectTeacher.data
        this.teachers = this.teachers.filter((teacher) => teacher.teacherName !== 'Super Admin')
      });
    },
  },
  created() {
    this.getSubject();
    this.studentData = JSON.parse(localStorage.getItem("studentData"));
    this.isUser = localStorage.getItem("isUser");
    console.log(this.$route.query);
  },
};
</script>
